import api from '~/services/api';
import {
  CreatePedidoProps,
  FinalizadorasPedidoProps,
} from '../../types/services';
import { PedidoPendenteProps, ResponseFormatted } from '../../types';
import { PedidoProps } from '../../types/context';

const createPedido = async (
  formData: CreatePedidoProps,
): Promise<
  ResponseFormatted<{
    cod_seq_pedido: number;
    num_pedido: number;
    tipo_status: number;
  }>
> => {
  const { data } = await api.post<
    ResponseFormatted<{
      cod_seq_pedido: number;
      num_pedido: number;
      tipo_status: number;
    }>
  >('/create-pedido', formData);
  return data;
};

const transmitirNFCe = async (
  cod_loja: number,
  cod_seq_pedido: number,
): Promise<any> => {
  const { data } = await api.post(`/pdv-online/transmitir-nfce/${cod_loja}`, {
    cod_seq_pedido,
  });
  return data;
};

const readDanfe = async (
  cod_loja: number,
  cod_seq_pedido: number,
): Promise<any> => {
  const { data } = await api.post(`/pdv-online/visualizar-danfe/${cod_loja}`, {
    cod_seq_pedido,
  });
  return data;
};

const getPedidoPendente = async (
  cod_loja: number,
  params: {
    cod_pessoa: number | null;
    dta_de: string;
    dta_ate: string;
    num_pedido: string | number;
  },
): Promise<ResponseFormatted<PedidoPendenteProps[]>> => {
  const { data } = await api.get<ResponseFormatted<PedidoPendenteProps[]>>(
    `/pdv-online/pedidos-pendentes/${cod_loja}`,
    {
      params,
    },
  );
  return data;
};

const updatePedidoPendente = async (
  num_pdv: number | null,
  cod_operador: number,
  dta_emissao: string,
  cod_seq_pedido: number,
  cod_loja: number,
  cod_pessoa: number | null,
  itemsPedido: PedidoProps[],
  finalizadoras: FinalizadorasPedidoProps[],
  des_observacao: string,
): Promise<any> => {
  const { data } = await api.put<any>(
    '/pdv-online/pedido-pendentes/finalizar',
    {
      num_pdv,
      cod_operador,
      dta_emissao,
      cod_seq_pedido,
      cod_loja,
      cod_pessoa,
      itemsPedido,
      finalizadoras,
      des_observacao
    },
  );
  return data;
};

export const pedidoService = {
  createPedido,
  transmitirNFCe,
  readDanfe,
  getPedidoPendente,
  updatePedidoPendente,
};
